<template>     
	<b-table
		:items="rentalPool"
		:fields="tableColumns"		
		
		primary-key="id"
		
		show-empty
		empty-text="No se encontraron registros"		
		class="position-relative mb-0"		
		style="overflow: auto"
	>

		<template #cell(code)="data">
			<span class="text-nowrap">
				{{ data.value ? "" : "N/A" }}
			</span>
		</template>

		<template #cell(status)="row">
			<span class="text-nowrap">        
				<b-badge pill :variant="row.item.status ? 'success': 'danger'" >
				{{ row.item.status ? "Activo" : "Desactivado" }}
				</b-badge>
			</span>
		</template>
		
		<template #cell(actions)="row">
			<div>
				<b-button
					size="sm"          
					:to="{name: 'edit-rental-pool', params: {id: row.item.id} }"               
					:class="{ 'd-none': !row.item.status }"
					variant="primary"
				>
					<feather-icon icon="Edit2Icon" size="12" />
				</b-button>
					<b-form-checkbox
						:class="{ 'd-none': row.item.status }"
						v-model="row.item.status"
						v-b-tooltip.hover.v-warning
						title="Cambiar Status"
						:value="true"
						switch
						inline
						:unchecked-value="false"
						@change="changeStatus(row.item, $event)"
						class="custom-control-success"
					>
					</b-form-checkbox>				
			</div>
		</template>
    </b-table> 
</template>
 
<script>
import { mapActions, mapMutations, mapState } from "vuex";


export default {
	created() {},
	data() {
		return {
			perPage: 10,
			currentPage: 1,
			perPageOptions: [5, 10, 15, 20, 25, 50, 100],
			searchQuery: "",
			sortBy: "id",
			isSortDirDesc: true,
			statusFilter: null,
			refRentalPoolListTable: null,
			beforeSearch: false,
			tableColumns: [
				{ key: "name", label: "Nombre", class: "text-center" },
				{ key: "resortname", label: "Resort" },
				// { key: "dateStart", label: "Fecha incio" },
				// { key: "dateEnd", label: "Fecha final" },
				{ key: "status", label: "Status", class: "text-center" },
				{ key: "actions", label: "Acciones", class: "text-center" },
			],
			items: [],
		};
	},
	computed: {
		...mapState("fivesClubCatalogs", ["rentalPool"]),
		...mapState("auth", ["user"]),  
	},
	methods: {
	
	},
};
</script>