<template>
    <div>
        <!-- <HousingNav/> -->
        <b-card>
            <div>
                <b-row align-v="center">
                    <b-col>
                        <h1 class="head-title">Pool de Rentas</h1>
                    </b-col>
                    <b-col class="head-btn">
                        <b-button
                            :to="{ name: 'create-rental-pool'}"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                        > <feather-icon icon="PlusIcon" style="color: white" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <RentalPoolList />
        </b-card>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import RentalPoolList from "@/modules/fivesClub/components/catalogs/housing/RentalPoolList"
import Ripple from 'vue-ripple-directive'
import HousingNav from "@/modules/fivesClub/components/catalogs/housing/HousingNav"

export default {
    directives: {Ripple, },
    components:{
        RentalPoolList,
        HousingNav
    },
    async created() {
        await this.getInitialData()
    },
    computed: {
        ...mapState('start', ['hotels']),
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['getInitialContentRentalPool']),
        ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setRentalPool']),

        ...mapMutations('start',['setHotels']),
        async getInitialData(){
            const { condos, subCondos,  hotels, rentalPool } = await this.getInitialContentRentalPool();
            this.setCondos(condos)
            this.setSubCondos(subCondos)
            this.setRentalPool(rentalPool)
            if(this.hotels.length === 0){
                this.setHotels(hotels)
            }
        },
    }
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
.head-title{
    font-size: 1.5rem;
    color: #000;
}
</style>